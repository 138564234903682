<template>
  <div id="chart" class="chart">
    <h3><u>Orders chart</u></h3>
    <div class="params">
      <div>
        <p>Select status</p>
        <select v-model="status" @change="getOrderWithStatus">
          <option value="">All</option>
          <option value="pending">Pending</option>
          <option value="delivered">Delivered</option>
          <option value="expired">Expired</option>
          <option value="completed">Completed</option>
        </select>
      </div>
      <div>
        <p>Choose start date</p>
        <input type="date" v-model="startDate" @change="getOrderWithStatus" />
      </div>
      <div>
        <p>Choose end date</p>
        <input type="date" v-model="endDate" @change="getOrderWithStatus" />
      </div>
      <div>
        <p v-if="changeData">Unick to display by number of item</p>
        <p v-else>Tick to display by total cost</p>
        <input
          type="checkbox"
          class="checkbox_input"
          v-model="changeData"
          @change="getOrderWithStatus"
        />
      </div>
    </div>
    <apexchart
      type="area"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
export default {
  name: "Chart",
  data: () => {
    return {
      itemDate: [],
      status: "",
      startDate: "2022-02-14",
      endDate: new Date().toISOString().substring(0, 10),
      changeData: false,

      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#6495ed"],
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "Items",
          data: [],
        },
      ],
    };
  },
  created() {
    this.getOrderWithStatus();
  },
  methods: {
    changeDataHandle() {},
    getOrderWithStatus() {
      axios
        .get(
          `https://test.ifeza.rwandabuildprogram.com/api/stats/orders?status=${this.status}&from=${this.startDate}&to=${this.endDate}`
        )
        .then((response) => {
          // console.log(response.data);
          const data = response.data;
          let initial = 0;
          const total_cost_arr = [];
          const total_item_arr = [];
          for (let item in data) {
            this.itemDate.push(item);
            const totalCost = data[item].reduce(function (prev, current) {
              return prev + current.total_cost;
            }, initial);
            const totalItems = data[item].reduce(function (prev, current) {
              return prev + current.items;
            }, initial);
            total_cost_arr.push(totalCost);
            total_item_arr.push(totalItems);
          }
          // console.log(total_cost_arr);
          // console.log(total_item_arr);

          if (this.changeData) {
            this.series = [
              {
                name: "Total cost",
                data: total_cost_arr,
              },
            ];
          } else {
            this.series = [
              {
                name: "Item",
                data: total_item_arr,
              },
            ];
          }

          // console.log(this.itemDate);
          this.chartOptions = {
            xaxis: {
              type: "datetime",
              categories: this.itemDate,
            },
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              x: {
                format: "dd/MMM/yyyy",
              },
            },
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 22px;
  font-weight: 400;
  text-align: center;
}
.params {
  width: 70%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}
.params div {
  width: 30%;
}
input,
select {
  width: 90%;
  padding: 5px;
  font-family: System-ui;
  font-size: 18px;
  border: 1px solid rgb(94, 139, 182);
  border-radius: 5px;
  color: rgb(5, 35, 63);
}
input,
select:focus {
  outline: none;
}
.checkbox_input {
  width: 40px;
  height: 35px;
  border: 50px;
}
</style>