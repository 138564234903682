<template>
  <div class="chart">
    <div class="chart_right">
      <h3>Specify the number of popular product you want</h3>
      <input type="number" v-model="arr_length" @change="getProductHandle" />
    </div>
    <div class="chart_left">
      <apexchart
        type="pie"
        width="800"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "PieChart",
  data: () => {
    return {
      arr_length: 0,
      series: [],
      chartOptions: {
        chart: {
          width: 800,
          type: "pie",
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  created() {
    this.getProductHandle();
  },
  methods: {
    getProductHandle() {
      axios
        .get(
          "https://test.ifeza.rwandabuildprogram.com/api/stats/popular-products"
        )
        .then((reponse) => {
          let data = reponse.data;
          let chartData = [];

          for (let product in data) {
            if (this.arr_length <= data[product].length) {
              this.series.push(data[product].length);
              this.chartOptions.labels.push(product);
            }
            chartData.push({
              label: product,
              series: data[product].length,
            });
          }

          let sorted = chartData.sort((a, b) => {
            return b.series - a.series;
          });

          if (this.arr_length <= this.series.length) {
            if (this.arr_length > 0) {
              let newSeries = [];
              let newLabel = [];
              for (let i = 0; i < this.arr_length; i++) {
                newSeries.push(sorted[i].series);
                newLabel.push(sorted[i].label);
              }
              this.series = newSeries;
              this.chartOptions = {
                labels: newLabel,
              };
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
/* .chart_left {
  width: 60%;
} */
.chart_right {
  width: 40%;
  margin: 30px auto;
}
input {
  width: 70%;
  margin: 10px auto;
  padding: 10px 12px;
  font-family: System-ui;
  font-size: 18px;
  border: 1px solid rgb(94, 139, 182);
  border-radius: 5px;
  color: rgb(5, 35, 63);
}
input:focus {
  outline: none;
}
</style>