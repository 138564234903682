<template>
  <div id="app" class="charts-container">
    <h2>Ifeza</h2>
    <chart />
    <pie-chart />
  </div>
</template>

<script>
import Chart from "./components/Chart.vue";
import PieChart from "./components/PieChart.vue";

export default {
  name: "App",
  components: {
    Chart,
    PieChart,
  },
  data() {
    return {};
  },
};
</script>


<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: System-ui;
  color: #2c3e50;
  margin-top: 30px;
}
.charts-container {
  width: 98%;
}
.charts-container h2 {
  text-align: center;
  font-size: 40px;
}
</style>
